import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios';
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/register',
    name: 'kelompok',
    component: () => import('../views/KelompokView.vue')
  },
  {
    path: '/register/:kelompok',
    name: 'register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/prepos/:responden_id',
    name: 'prepos',
    component: () => import('../views/PreposView.vue')
  },
  {
    path: '/kuesioner/:prepos/:responden_id',
    name: 'kuesioner',
    component: () => import('../views/KuesionerView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
