import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
    state: {
        serverURL   : 'https://lumen-bocesting.mibplus.id/',
        token       : null,
        ID          : null,
        kode        : null,
        nama        : null
    },
    getters: {
        getServerURL(state){ return state.serverURL },

        getToken(state){ return state.token },
        getId(state){ return state.ID },
        getKode(state){ return state.nip },
        getNama(state){ return state.nama }
    },
    mutations: {
        mutToken(state,payload){ state.token = payload },
        mutId(state,payload){ state.ID = payload },
        mutKode(state,payload){ state.nip = payload },
        mutNama(state,payload){ state.nama = payload },
    },
    actions: {
        setToken(context,payload){ context.commit('mutToken',payload) },
        setId(context,payload){ context.commit('mutId',payload) },
        setKode(context,payload){ context.commit('mutNip',payload) },
        setNama(context,payload){ context.commit('mutNama',payload) },
    },
    plugins: [createPersistedState()]
})
